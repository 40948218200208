import React from "react";

export const Team = (props) => {
  return (
    <div id="app">
      <div className="container">
        <div className="row d-flex">
        <div className="col-xs-12 col-md-6">
          {" "}
          <img src="img/arco-app.png" className="img-responsive" alt="" />{" "}
        </div>
        <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>Conta Segura</h2>
              <p>{props.data ? props.data.paragraph : "loading..."}</p>
            </div>
        </div>
         
        </div>
      </div>
    </div>
  );
};
