import React from "react";

export const Gallery = (props) => {

  const openInNewTab = (url) => {
    window.open(url, "_blank", "noreferrer");
  };
  
  return (
    <div id="gallery">
      <div className="container">
        <div className="row d-flex">
        <div className="col-xs-12 col-md-6">
            <div className="about-text">
              <h2>{props.data ? props.data.title : "loading..."}</h2>
              <button className="btn btn-custom btn-outlined-white btn-lg page-scroll" onClick={() => openInNewTab("https://app.arcobank.com.br/register")}>
                  Abra sua conta
                </button>
            </div>
        </div>
        <div className="col-xs-12 col-md-6">
          {" "}
          <img src="img/aeja-arco.png" className="img-responsive" alt="" />{" "}
        </div>
        </div>
      </div>
    </div>
  );
};
